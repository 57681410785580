.header-mob-main {
    display: none;
    width: 100vw;
    background-color: #fff;
    position: fixed;
    height: 50px;
    top: 0px;
    z-index: 2;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid #e9e7e7;
    padding:10px 0px 10px 0px;
    .header-mob-content{
   
        display: flex;
        justify-content: space-between;
        align-items: center;    
        padding: 0px 20px 0px 24px;
        .logo img {
            display: block;
            height: 50px;

        }

    }
    
}
.drop-down-menu{
    color: #000;
    z-index: 4;
    height: 100vh;
    background-color: #fff;
    padding: 20px 0px 0px 20px;
    .drop-item{
        padding: 0px 0px 20px 0px;
        color:#010845;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
        .header-mob-drop-down-item-link{
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 0px 0px 12px 0px;
        }
    }
    .drop-item a{
        text-decoration: none;
        color:#010845;
    }
    .drop-item-group{
        display: flex;
        color:#010845;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
        .mob-arrow-down{
            padding: 4px 0px 0px 4px;
        }
    }
}

@media (max-width:1000px) {
    .header-mob-main{
        display: block;
        z-index: 4;
    }
}