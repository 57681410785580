.partners-main{
    max-width: 1312px;
    margin: 0 auto;
    padding: 90px 0px 0px 0px;
    .partners-text-block{
        text-align: center;
        max-width: 640px;
        margin: 0 auto;
        padding: 0px 0px 40px 0px;
        .partners-top-capture{
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            color: #ACACAC;
            padding: 0px 0px 8px 0px;
        }
        .partners-capture{
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            padding: 0px 0px 24px 0px;
        }
        .partners-description{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #34396A;
        }
    }
    .partners-carousel-vrapper{
        width: 100%;
        padding: 0px 0px 90px 0px;
        .item{
            width: 310px;
            height: 120px;
            border-radius: 16px;
            background-color: #F8F8F8;
            display: flex;
            justify-content: center;
            align-items: center;
            margin:  0 auto;
            .item-image-link{
                display: block;
                margin:  0 auto;
            }
        }
    }
    .partners-bottom{
        max-width: 656px;
        margin:  0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding:  0px 0px 0px 0px;
        .item{
            width: 310px;
            height: 120px;
            border-radius: 16px;
            background-color: #F8F8F8;
            display: flex;
            justify-content: center;
            align-items: center;
            margin:  20px auto;
            .item-image-link{
                display: block;
                margin:  0 auto;
            }
        }
    }
}
@media (max-width:1024px) {
    .partners-main{
        padding: 90px 0px 0px 0px;
        .partners-text-block{
            padding: 0px;
        }
        
    }
}