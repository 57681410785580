.blog-main {
    width: 100%;
    background-color: #010845;
    color: #fff;

    .blog-content {
        max-width: 1312px;
        margin: 0 auto;

        .blog-caprute-blog {
            margin: 0 auto;
            text-align: center;
            padding: 68px 0px 0px 0px;

            .blog-sub-capture {
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                padding: 8px 0px 0px 0px;
            }

            .blog-capture {
                font-size: 40px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                padding: 0px 0px 40px 0px;
            }
        }

        .blog-slider-block {
            width: 100%;
            padding: 0px 0px 152px 0px;

            .blog-card {
                max-width: 416px;
                height: 522px;
                background-color: #fff;
                color: #010845;
                border-radius: 16px;
                margin: 0 auto;
                position: relative;
            }

            .blog-card-content {
                padding: 16px 20px 16px 20px;
            }

            .blog-image img {
                max-width: 100%;
                display: block;
            }

            .blog-date {
                color: #FF2C3C;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                padding: 16px 0px 8px 0px;
            }

            .short-blog-capture {
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 0px 0px 12px 0px;
            }

            .short-blog-description {
                position: absolute;
                left: 20px;
                bottom: 110px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            .link-to-full-log-page {
                width: 157px;
                height: 50px;
                border-radius: 32px;
                background: #FF2C3C;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: absolute;
                left: 20px;
                bottom: 20px;
            }
        }
    }
}

@media (max-width:866px) {
    .blog-main {
        .blog-content {
            .blog-slider-block {
                .blog-card {
                    max-width: 348px;
                    height: 457px;

                    .blog-card-content {
                        .short-blog-capture {
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }

                        .short-blog-description {
                            bottom: 80px;
                        }
                    }
                }
            }
        }
    }

}

@media (max-width:428px) {
    .blog-main {
        .blog-content {
            .blog-caprute-blog {
                padding: 32px 0px 0px 0px;

                .blog-capture {
                    font-size: 32px;
                }
            }
        }
    }

}