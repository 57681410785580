.news-main {
    width: 100%;
    margin: 0 auto;
    background: #FF2C3C;
    color: #fff;
    padding: 10px 0px 0px 0px;

    .news-content-wrapper {
        max-width: 1332px;
        margin: 0 auto;

        .news-content {
            padding: 64px 0px 64px 0px;

            .news-capture {
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                text-align: center;
                padding: 0px 0px 8px 0px;
            }

            .hews-hashtag {
                font-size: 40px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                text-align: center;

            }

            .news-description {
                padding: 24px 0px 72px 0px;
                max-width: 600px;
                margin: 0 auto;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: center;
            }

            .news-card-capture {
                max-width: 650px;
                margin: 0 auto;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 0px 0px 40px 0px;
                text-align: center;
            }

            .news-card-wrapper {
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding: 0px;

                .news-item-card {
                    width: 416px;
                    height: 383px;
                    border-radius: 16px;
                    background-color: #fff;
                    color: #010845;
                    margin: 20px auto;

                    .news-item-content {
                        position: relative;
                        padding: 24px 24px 4px 24px;

                        .news-item-capture {

                            padding: 20px 0px 0px 0px;
                            font-size: 28px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }

                        .news-item-description {
                            width: 369px;
                            position: absolute;
                            top: 208px;
                            color: var(--Blue-2, #34396A);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            /* 150% */
                            text-align: justify;
                        }
                    }
                }
            }

            .news-card-wrapper-slider {
                display: none;

                .news-item-card {
                    width: 416px;
                    height: 383px;
                    border-radius: 16px;
                    background-color: #fff;
                    color: #010845;
                    margin: 20px auto;

                    .news-item-content {
                        position: relative;
                        padding: 24px 24px 4px 24px;

                        .news-item-capture {

                            padding: 20px 0px 0px 0px;
                            font-size: 28px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }

                        .news-item-description {
                            width: 369px;
                            position: absolute;
                            top: 208px;
                            color: var(--Blue-2, #34396A);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            /* 150% */
                            text-align: justify;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:1312px) {
    .news-main {
        padding: 40px 0px 0px 0px;

        .news-content-wrapper {

            .news-content {

                .news-card-wrapper {

                    .news-item-card {
                        width: 294px;
                        height: 330px;

                        .news-item-content {

                            .news-item-capture {
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }

                            .news-item-description {
                                width: 246px;
                                position: absolute;
                                top: 161px;
                                color: var(--Blue-2, #34396A);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:1000px) {
    .news-main {

        .news-content-wrapper {

            .news-content {

                .hews-hashtag {
                    font-size: 32px;
                }
            }
        }
    }
}

@media (max-width:960px) {
    .news-main {

        .news-content-wrapper {

            .news-content {

                .news-card-wrapper {
                    display: none;
                }

                .news-card-wrapper-slider {
                    display: block;

                    .news-item-card {
                        width: 294px;
                        height: 330px;

                        .news-item-content {

                            .news-item-capture {
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }

                            .news-item-description {
                                width: 246px;
                                position: absolute;
                                top: 161px;
                                color: var(--Blue-2, #34396A);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:680px) {
    .news-main {
        .news-content-wrapper {
            .news-content {
                .hews-hashtag {
                    font-size: 15px;
                }
                .news-description {
                    font-size: 14px;
                    padding: 16px 0px 24px 0px;
                }
                .news-card-capture{
                    font-size: 16px;
                    padding: 0px 0px 16px 0px;
                }
            }
        }
    }
}