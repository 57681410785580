.post1 {

    max-width: 100%;

    .post-hero {
        width: 100%;
        margin: 70px auto 100px;
        background-image: url(../../../assets/img/post1Img.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .post1-capture {
        max-width: 951px;
        margin: 0 auto;
        color: #fff;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 120px 0px 120px 0px;
    }
    @media (max-width:600px) {
        .post1-capture{
            font-size: 40px;
            text-align: center;
        }
    }

    .post1-content-wrapper {
        max-width: 1312px;
        margin: 0 auto;
        color: #34396A;
        padding: 0px 0px 100px 0px;
        .post-navigate {
            width: 100%;
            padding: 0px 0px 32px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .back-to-main {
                width: 160px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                .arrow{
                    padding-top: 5px;
                }
                .back {
                    color: #FF2C3C;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 130%;
                }
            }
            .post-date{
                color:#010845;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
        .post-navigate a{
            text-decoration: none;
        }
    }
    p {
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
    ol li{
        font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
padding:  0px 0px 8px 0px;
    }
}
@media (max-width:1380px) {
    .post1{
        width: 96%;
        margin: 0 auto;
        
    }
}