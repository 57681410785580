.contact-us-main {
    max-width: 1312px;
    margin: 0 auto;
    padding: 90px 0px 190px 0px;

    .head-line-content {
        width: 100%;

        .head-line-capture {
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            text-align: center;
            padding: 0px 0px 40px 0px;
        }

        .agent-card-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .agent-card {
                width: 626px;
                margin: 0 auto;

                .agent-content-wrapper {
                    width: 100%;
                    border-radius: 20px;
                    background: #F8F8F8;

                    .agent-content {
                        padding: 24px;
                        display: flex;
                        align-items: center;

                        .agent-img {
                            display: block;
                            width: 168px;
                            margin: 0 auto;
                        }

                        .agent-info {
                            width: 302px;
                            padding: 0px 0px 0px 24px;

                            .role {
                                color: #010845;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                padding: 0px 0px 56px 0px;
                            }

                            .full-name {
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }

                            .phone a {
                                color: #010845;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                                padding: 0px 0px 8px 0px;
                                text-decoration: none;
                            }

                            .email a {
                                color: #010845;
                                text-decoration: none;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }


                        }

                    }
                }
            }
        }


    }
}

@media (max-width:1310px) {
    .contact-us-main {

        .head-line-content {

            .agent-card-wrapper {

                .agent-card {
                    width: 456px;

                    .agent-content-wrapper {
                        .agent-content {
                            .agent-info {
                                .agent-img img {
                                    width: 168px;
                                }

                                .email a {
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;

                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

@media (max-width:938px) {
    .contact-us-main {
        padding: 90px 0px 80px 0px;

        .head-line-content {

            .agent-card-wrapper {
                flex-direction: column;

                .agent-card {
                    width: 626px;
                    margin: 20px auto;

                    .agent-content-wrapper {
                        .agent-content {

                            .agent-info {
                                .agent-img img {
                                    width: 168px;
                                }

                                .email a {
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;

                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

@media (max-width:656px) {
    .contact-us-main {
        width: calc(100% - 40px);
        padding: 70px 0px 70px 0px;

        .head-line-content {
            .head-line-capture {
                font-size: 30px;
            }

            .agent-card-wrapper {
                .agent-card {
                    width: 100%;
                    margin: 20px auto;
                }
            }
        }
    }
}

@media (max-width:498px) {
    .contact-us-main {

        .head-line-content {

            .agent-card-wrapper {
                .agent-card {
                    .agent-content-wrapper {

                        .agent-content {
                            flex-direction: column;
                            .agent-info{
                                text-align: center;
                                .role{
                                    padding-bottom: 12px;
                                }
                                .phone a{
                                    padding: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}