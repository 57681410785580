.Form-main {
    width: 100%;
    background-color: #010845;
    color: #fff;
    margin-top: 90px;

    .form-content {
        max-width: 864px;
        margin: 0 auto;
        padding: 0px 0px 76px 0px;

        .form-top-capture {
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            padding: 64px 0px 8px 0px;
        }

        .form-capture {
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            padding: 0px 0px 40px 0px;
        }

        @media (max-width:428px) {
            .form-top-capture {
                font-size: 16px;
            }
        }

        @media (max-width:428px) {
            .form-capture {
                font-size: 27px;
                padding: 0px 0px 20px 0px;
            }
        }
    }
    @media (max-width:1034px) {
        .form-content{
            padding: 0px 0px 32px 0px;
        }
    }

    .form {
        width: 100%;

        .inputs-line {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            

            padding: 0px 0px 32px 0px;

            .input-block {

                .red {
                    box-shadow: 0px 0px 18px #ff0000;
                }

                .signature {
                    padding: 0px 0px 10px 0px;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }


            .input-block input {
                width: 380px;
                padding: 15px 20px;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                background: #FFF;
                border: none;
                outline: none;

            }
            @media (max-width:868px) {
                .input-block input {
                    width: 344px;
                }
            }
            @media (max-width:792px) {
                .input-block input {
                    width: 324px;
                }
            }
            @media (max-width:738px) {
                .input-block input {
                    width: 90%;
                    margin: 0 auto;   
                }
                .input-block  {
                    width: 96%;
                    margin: 0 auto;
                }
                .inputs-wrapper{
                    width: 100%;
                    text-align: center;
                }
            }
           
            .input-block input::placeholder {
                color: #ACACAC;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        @media (max-width:1000px) {
            .inputs-line {
                .input-block {
                    margin: 10px auto;
                }
            }
        }
        @media (max-width:738px) {
            .inputs-line {
                width: 100%;
                display: block;
            }
        }

        .text-area-block {
            width: 100%;
            padding: 0px 0px 10px 0px;
            .red {
                box-shadow: 0px 0px 18px #ff0000;
            }
            .signature {
                padding: 0px 0px 10px 0px;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        .checkboxes-wrapper {
            width: 100%;
            padding: 0px 0px 20px 0px;
           
            .red {
                box-shadow: 0px 0px 18px #ff0000;
            }
            .checkbox-item-wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: baseline;

                .checkbox-description-with-link {
                    color: var(--White, #FFF);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    border-radius: 8px;
                    padding: 8px;
                }

                .statut {
                    color: var(--White, #FFF);
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;

                    cursor: pointer;
                }

                .checkbox-description-with-link a {
                    color: var(--White, #FFF);
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
            

            .checkbox-item-wrapper input {
                margin-right: 10px;
            }
        }
        @media (max-width:428px) {
            .checkboxes-wrapper{
                width: 96%;
                margin: 0 auto;
             
            }
        }

        @media (max-width:1000px) {
            .text-area-block {
                width: 98%;
                margin: 0 auto;
            }
        }
        @media (max-width:768px) {
            .text-area-block {
                width: 97%;
                
            }
        }
        @media (max-width:738px) {
            .text-area-block {
                width: 92%;
                
            }
        }
        @media (max-width:428px) {
            .text-area-block {
                width: 95%;
                
            }
        }

        .text-area-block textarea {
            margin: 0 auto;
            height: 141px;
            padding: 15px 20px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 8px;
            background: #FFF;
            border: none;
            padding: 0;
            margin: 0;
            resize: none;
            overflow: hidden;
            width: 100%;
            box-sizing: border-box;
            padding: 10px;
        }

        .text-area-block textarea::placeholder {
            color: #ACACAC;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
        }

        .button-wrapper {
            width: 250px;
            height: 50px;
            border-radius: 32px;
            background: #FF2C3C;
            color: #fff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;

        }
        @media (max-width:1000px) {
            .button-wrapper {
                margin-left: 20px;
            }
        }
        @media (max-width:738px) {
            .button-wrapper {
                width: 90%;
                margin: 0 auto;
            }
        }
    }

}

@media (max-width:428px) {
    .form-main {
        margin-top: 40px;   
    }
}