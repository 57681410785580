.about-main {
    max-width: 1312px;
    margin: 0 auto;
    .about-content {
        width: 100%;
        padding: 90px 0px 0px 0px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        .about-img {
            max-width: 626px;
        }
        .about-img img {
            width: 100%;
            display: block;
            margin: 0 auto;
        }
        .about-text {
            max-width: 586px;

            .about-capture {
                font-size: 40px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                padding: 0px 0px 32px 0px;
                text-align: start;
            }
            
            .about-description{
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
          
        }
    }
   
    @media (max-width:1210px) {
        .about-content{
            width: 100% ;
            margin: 0 auto;
            padding: 90px 0px 0px 0px;
            align-items: start;
            .about-img {
                margin: 0 auto;
                max-width: 476px;

            }
            .about-img img{
               
                display: block;
                width: 100%;
            }
            .about-text{
                margin: 0 auto;
                padding: 30px 0px 0px 0px;
                .about-capture{
                    text-align: start;
                    padding-bottom: 20px;
                }
                .about-description{
                    max-width: 456px;

                    text-align: start;
                    font-size: 16px;

                }
                
            }
        }
    }
}
@media (max-width:1320px) {
    .about-main {
        width: calc(100% - 40px);
        .about-content{
            .about-text{
                padding-left:20px;
            }
        }
    }
}
@media (max-width:768px) {
    .about-main {
        width: calc(100% - 40px);
        .about-content{
            padding: 70px 0px 0px 0px;
            .about-img {
                width: 348px;

            }
            .about-text{
                padding:0px 0px 0px 20px;
                .about-capture{
                    font-size: 32px;
                    padding: 0px 0px 8px 0px;
                }
                .about-description{
                    font-size: 14px;
                    line-height: normal;
                    padding: 0px 0px 16px 0px;
                }
            }
        }
    }
}
@media (max-width:744px) {
    .about-main {

        .about-content{
            flex-direction: column-reverse;
            
            .about-img {

            }
            .about-text{
                text-align: center;
                .about-capture{
                    
                    text-align: center;
                }
                .about-description{
                    text-align: center;
                }
            }
        }
    }
}
@media (max-width:368px) {
    .about-main {
        width: calc(100% - 20px);
        .about-content{
            flex-direction: column-reverse;
            
            .about-img {
                padding: 0px;
                width: 100%;
            }
            .about-text{
                padding: 0px;
                .about-capture{
                    
                    text-align: center;
                }
                .about-description{
                    text-align: center;
                }
            }
        }
    }
}
