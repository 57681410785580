.privacy-wrapper{
    margin-top: 100px;
    background:
     #FFFFFF;
     font-size: 16px;
     line-height: 22px;
     color: #010845;
     .privacy-content{
        max-width: 1312px;
        margin: 0 auto;
        padding: 100px 10px 100px 10px;
        h1{
            text-align: center;

            padding: 0px 0px 50px 0px;
        }
        h2{
            padding: 20px 0px 20px 0px ;
            text-align: center;

        }
        li{
            padding: 0px 0px 10px 0px;
        }
        ol li{
            padding-top: 10px;
        }
        .ul-nav{
            text-decoration: none;
            list-style: none;
        }
     }
}