.footer-main {
    width: 100%;
    background: #F8F8F8;

    .footer-content-wrapper {
        max-width: 1312px;
        margin: 0 auto;

        padding: 44px 0px 30px 0px;

        .footer-logo {
            display: block;
            border-bottom: 1px solid rgba(1, 8, 69, 0.30);
        }

        .footer-links {
            width: 100%;
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            padding: 32px 0px 10px 0px;

            .footer-links-item {
                color: var(--Blue, #010845);
                /* Link Title */

                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

                .footer-email a{
                    padding: 10px 0px 0px 0px;
                    color: var(--Blue, #010845);
                    /* Link Title */
    
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .subLinks {
                    padding: 0px 0px 8px 0px;
                }
            }

            .footer-links-item a {
                text-decoration: none;
                color: #010845;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

            }

            .footer-links-item a:hover {
                color: #FF2C3C;
            }

            .footer-links-item ul {
                margin: 0px;
                padding: 0px 0px 8px 18px;
            }

            .footer-links-item ul li a {
                color: #010845;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 0px 0px 8px 0px;
            }
        }

        .footer-bottom {
            padding: 20px 0px 0px 0px;
            border-top: 1px solid rgba(1, 8, 69, 0.30);
            ;
        }

        .footer-bottom-statut {
            padding: 10px 0px 30px 0px;
            text-decoration: none;
            color: #010845;
            font-weight: 600;
            cursor: pointer;
        }

        .footer-bottom-statut:hover {
            color: #FF2C3C;
        }

        .footer-bottom a {
            text-decoration: none;
            color: #010845;
            font-weight: 600;
        }

        .footer-bottom a:hover {
            color: #FF2C3C;
        }

    }

    @media (max-width:1350px) {
        .footer-content-wrapper {
            max-width: 94%;
        }
    }

    @media (max-width:600px) {
        .footer-content-wrapper {
            .footer-links {
                flex-direction: column;

                .footer-links-item {
                    padding: 0px 0px 30px 0px;
                }
            }
        }
    }
}