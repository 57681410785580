.post3 {

    max-width: 100%;

    .post-hero {
        width: 100%;
        margin: 70px auto 100px;
        background-image: url(../../../assets/img/post3Img.png);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .post3-capture {
        max-width: 951px;
        margin: 0 auto;
        color: #fff;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 120px 0px 120px 0px;
        text-align: center;
    }
    @media (max-width:600px) {
        .post3-capture{
            font-size: 40px;
            text-align: center;
        }
    }
    .p-description{
        display: block;
        padding: 0px 0px 10px 0px;
    }

    .post3-content-wrapper {
        max-width: 1312px;
        margin: 0 auto;
        color: #34396A;
        padding: 0px 0px 100px 0px;
        .sub-capture{
            color: #010845;

            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal
        }
        .sub-capture-light{
            color: #010845;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .ale{
            color: #34396A;

            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
        .bigText{
            color: #34396A;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-decoration-line: underline;
        }
        .vertical-line {
            position: relative;
            margin: 30px 0px 30px 0px;
        }
        
        .vertical-line::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 4px;
            border-radius: 10px;
            height: 100%; 
            background-color: #FF2C3C; /* Цвет черты */
            
        }
        .vertical-line p {
            padding-left: 20px; 
            font-style: italic;
        }
        .post-navigate {
            width: 100%;
            padding: 0px 0px 32px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .back-to-main {
                width: 160px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                .arrow {
                    padding-top: 5px;
                }

                .back {
                    color: #FF2C3C;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 130%;
                }
            }

            .post-date {
                color: #010845;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
        .post-navigate a{
            text-decoration: none;
        }
       
    }

    p {
        text-align: justify;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
    }

    ol li {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        padding: 0px 0px 8px 0px;
    }
    ul li{
        padding: 10px 0px 10px 0px;
    }
    
}
@media (max-width:1380px) {
    .post3{
        width: 96%;
        margin: 0 auto;
        
    }
}