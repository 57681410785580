.advantages-main {
    max-width: 1312px;
    margin: 0 auto;
    padding: 120px 0px 0px 0px;

    .advantages-capture-block {
        max-width: 700px;
        margin: 0 auto;
        text-align: center;

        .advantage-small-capture {
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            color: #ACACAC;
            padding: 8px 0px 0px 0px;
        }

        .advantages-capture {
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            padding: 0px 0px 24px 0px;
        }

        .advantages-description {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 0px 0px 40px 0px;
        }
    }

    .advantages-cards-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .advantages-card {
            width: 416px;
            height: 213px;
            margin: 10px auto;

            .advantages-card-content {
                .advatnages-card-img {
                    padding: 0px 0px 16px 0px;
                }

                .advantages-card-capture {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding: 0px 0px 4px 0px;
                }

                .advantages-card-description {
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

        }
    }
}

@media (max-width:1024px) {
    .advantages-main {
        .advantages-cards-wrapper {
            .advantages-card {
                width: 292px;
                height: 270px;
                .advantages-card-content {
                    .advatnages-card-img {
                        padding: 0px;
                        .advantages-card-capture {
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }
                        .advantages-description {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width:680px) {
    .advantages-main {
        .advantages-capture-block{
            .advantages-capture {
                font-size: 30px;
                padding: 0px 0px 12px 0px;
            }
            .advantages-description {
                font-size: 14px;
            }
        }
        .advantages-cards-wrapper {
            .advantages-card {
                width: 292px;
                height: 270px;
                .advantages-card-content {
                    .advatnages-card-img {
                        padding: 0px;
                       text-align: center;
                    }
                    .advantages-card-capture {
                        text-align: center;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                    .advantages-card-description {
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }
    }
}