.success-main-wrapper {
    position: relative;
    width: 740px;
    height: 390px;
    background-color: #fff;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    .success-content {
        margin: 0 auto;
        text-align: center;

        .success-message-header {
            color: #010845;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            padding: 0px 0px 16px 0px;
        }

        .success-message-description {
            color: rgba(1, 8, 69, 0.60);
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}

@media (max-width:800px) {
    .success-main-wrapper {
        width: 500px;
    }
}

@media (max-width:538px) {
    .success-main-wrapper {
        width: 400px;

        .success-content {
            .success-message-header {
                font-size: 24px;

            }
            .success-message-description {

                text-align: center;
                font-size: 16px;
               
            }
        }
    }
}
@media (max-width:450px) {
    .success-main-wrapper {
        width: 300px;
        height: 290px;
        .success-content {
            .success-message-header {
                font-size: 24px;

            }
            .success-message-description {

                text-align: center;
                font-size: 16px;
               
            }
        }
    }
}