.hero-main-wrapper {
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    .hero-main {

        margin-top: 00px;
        max-width: 100%;
        background-image: url('../../assets//img/heroImg.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: #fff;

        .hero-content {
            max-width: 867px;
            margin: 0 auto;
            padding: 170px 0px 129px 0px;
            text-align: center;

            .hero-capture {
                font-size: 64px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                padding: 0px 0px 32px 0px;
            }

            @media (max-width:768px) {
                .hero-capture {
                    font-size: 40px;
                }
            }
            @media (max-width:428px) {
                .hero-capture {
                    font-size: 32px;
                }
                
            }
            .hero-description {
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;

            }
            @media (max-width:428px) {
                .hero-description {
                    font-size: 18px;
                }
                
            }
        }

        @media (max-width:768px) {
            .hero-content {
                max-width: 867px;
                margin: 0 auto;
                padding: 180px 0px 120px 0px;
                text-align: center;
            }
        }
        @media (max-width:428px) {
            .hero-content {
                padding:120px 0px 54px 0px;

            }
        }
    }
}