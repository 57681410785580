.cookies-main-wrapper {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    bottom: 0px;
    z-index: 100;
    background-color: #ffffff;
    .cookies-wrapper {
        max-width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 100;
        .cookies-content {
            padding: 10px;
            max-width: 100%;
            background-color: #ffffff;
            margin: auto 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            .cookies-text {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #010845;
            }
            .cookies-buttons-wrapper {
                width: 200px;
                .button-cookies-accept {
                    display: flex;
                    padding: 12px 52px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: #fff;
                    border-radius: 32px;
                    background: var(--Red, #FF2C3C);
                    cursor: pointer;
                    margin-left: 20px;
                    margin-right: 20px;
                }
                .closePopup {
                    margin: 0px 0px 0px 20px;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    cursor: pointer;
                }
            }
            .cookies-button-wrapper button {
                border: none;
            }
        }
        @media (max-width:700px) {
            .cookies-content {
                flex-direction: column;
                .cookies-text {
                    padding-bottom: 10px;
                }
            }
        }
      
    }

}
