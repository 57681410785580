.targets-main {
    max-width: 1312px;
    margin: 0 auto;
    padding: 90px 0px 0px 0px;

    .targets-text {
        max-width: 760px;
        margin: 0 auto;
        text-align: center;

        .targets-capture {
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            padding: 0px 0px 24px 0px;
        }

        .targets-description {
            max-width: 752px;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 0px 0px 40px 0px;
            color: #010845
        }

    }

    .targets-card-wrapper {
        width: calc(100%);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0px 0px 100px 0px;

        .card {
            width: 310px;
            height: 244px;
            border-radius: 16px;
            background: #F8F8F8;

            .card-content {
                padding: 30px;
                position: relative;

                .card-img img {
                    display: block;
                    margin: 0 auto;
                }

                .card-description {
                    padding-top: 20px;
                    width: 260px;
                    margin: 0 auto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-align: center;
                }
            }
        }

    }

    .target-bottom {
        width: 100%;
        padding: 0px 0px 30px 0px;

        .target-bottom-content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .target-bottom-text {
                max-width: 626px;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

        }

    }

}

@media (max-width:1320px) {
    .targets-main {
        width: calc(100% - 40px);
    }
}

@media (max-width:1100px) {
    .targets-main {

        .targets-card-wrapper {
            width: calc(100% - 20px);
            margin: 0 auto;
            padding: 0px 0px 56px 0px;

            .card {
                width: 456px;
                margin: 20px auto;
            }
        }
    }

    .target-bottom {
        .target-bottom-content {
            width: calc(100% - 60px);
            margin: 0 auto;

            .target-bottom-text {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                width: 456px;
            }

            .target-bottom-img {
                width: 456px;
            }

            .target-bottom-img img {
                width: 100%;
            }
        }
    }
}

@media (max-width:1000px) {
    .targets-main {
        width: calc(100%);
        .targets-card-wrapper {
            .card {
                width: 348px;
                margin: 20px auto;
            }
        }
        .target-bottom {
            .target-bottom-content {
                width: calc(100% - 40px);

                .target-bottom-text {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    width: 348px;
                }

                .target-bottom-img {
                    width: 348px;
                }

                .target-bottom-img img {
                    width: 100%;
                }
            }
        }
    }
}
@media (max-width:744px) {
    .targets-main {
        padding: 70px 0px 0px 0px;
    }
    .target-bottom {
        .target-bottom-content {
            flex-direction: column;
            .target-bottom-text {
               
                text-align: center;
                padding: 0px 0px 16px 0px;
            }
        }
    }
}