.header-main {
    width: 100%;
    background-color: #fff;
    position: fixed;
    height: 50px;
    top: 0px;
    z-index: 200;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 0px 10px 0px;
}

.header-content {
    max-width: 1312px;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px 0px 10px;
    .header-nav-left {
        padding: 0px 0px 0px 0px;
        width: 366px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .drop-menu {
            position: relative;
            display: flex;
            align-items: center;

            .drop-down {
                width: 184px;
                position: absolute;
                top: 40px;
                left: -91px;
                border-radius: 8px;
                padding: 24px 24px 4px 24px;
                background: #FFF;
                box-shadow: 0px 0px 6px 0px rgba(1, 8, 69, 0.25);
                .drop-down-item{
                    padding: 0px 0px 20px 0px;
                    cursor: pointer;

                }
                .drop-down-item:hover{
                    color:#FF2C3C
                }
            }

            .arrow-down img {
                display: block;
                width: 100%;
                padding-top: 4px;
            }
            .drop-links{
                color: #010845;
                cursor: pointer;
            }
            .drop-links:hover{
                color: #FF2C3C;
            }
        }
    }
    .header-nav-left a {
        text-decoration: none;
        color: #010845;
    }
    .header-nav-left a:hover{
        color:#FF2C3C
    }

    .header-nav-right {
        padding: 0px 0px 0px 0px;
        width: 366px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .drop-menu {
            position: relative;
            display: flex;
            align-items: center;
            .drop-down {
                width: 184px;
                position: absolute;
                top: 40px;
                left: -66px;
                border-radius: 8px;
                padding: 24px 24px 4px 24px;
                background: #FFF;
                box-shadow: 0px 0px 6px 0px rgba(1, 8, 69, 0.25);
                .drop-down-item{
                    padding: 0px 0px 20px 0px;
                }
                .drop-down-item:hover{
                    color:#FF2C3C
                }
            }
            .arrow-down img {
                display: block;
                width: 100%;
                padding-top: 4px;
            }
            .drop-links{
                color: #010845;
                cursor: pointer;
            }
            .drop-links:hover{
                color: #FF2C3C;
            }
        }
    }
    .header-nav-right a {
        text-decoration: none;
        color: #010845;
    }
    .header-nav-right a:hover{
        color:#FF2C3C
    }

    .header-logo {
        width: 190px;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo-image img {
            display: block;
            height: 50px;
        }

        .logo-text {
            width: 100px;
            text-align: start;

        }
    }
}
.hidden {
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    opacity: 0;
  }
  .header-main:hover {
    /* Стили для видимого хедера при наведении курсора */
    transform: translateY(0);
    transition: all 500ms;
    opacity: 1;
  }
@media (max-width:1000px) {
    .header-main{
        display: none;
    }
}