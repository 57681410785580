.Vebinars-blog-main {
    max-width: 1312px;
    margin: 0 auto;
    padding: 70px 0px 140px 0px;
    text-align: center;

    .vebinars-sub-capture {
        color: #ACACAC;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        padding: 0px 0px 8px 0px;
    }

    .vebinars-capture {
        color: #010845;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        padding: 0px 0px 50px 0px;
    }

    .vebinars-description-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 30px 0;

        .vebinars-description-img img {
            display: block;
            object-fit: cover !important;
            max-width: 600px;
        }

        .vebinars-right-description {
            max-width: 500px;
            text-align: start;
            padding: 0px 0px 0px 60px;
            .vebinars-button-wrapper{
                text-decoration: 'none';
                 width: '188px';
            }
            .vebinars-date {
                color: #FF2C3C;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 0px 0px 12px 0px;
            }

            .vebinars-descriptions-header {

                color: #010845;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 0px 0px 16px 0px;
            }

            .vebinars-description {
                max-width: 420px;
                color: #34396A;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                padding: 0px 0px 24px 0px;
            }

            .vebinars-red-button {
                border-radius: 32px;
                background: #FF2C3C;
                color: #fff;
                width: 188px;
                height: 49px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }

            .vebinars-red-button a {

                color: #fff;

                cursor: pointer;
            }
        }

    }

}

@media (max-width:1296px) {
    .Vebinars-blog-main {
        width: calc(100% - 40px);

    }
}

@media (max-width:1100px) {
    .Vebinars-blog-main {
        .vebinars-description-wrapper {
            justify-content: space-between;

            .vebinars-right-description {
                padding: 0px;
            }

            .vebinars-description-img {
                width: 456px;
            }

            .vebinars-description-img img {
                width: 100%;
                display: block;
            }
        }

    }
}

@media (max-width:1000px) {
    .Vebinars-blog-main {
        padding: 70px 0px 80px 0px;

        .vebinars-description-wrapper {
            justify-content: space-between;

            .vebinars-right-description {
                padding: 0px;
            }

            .vebinars-description-img {
                width: 348px;
            }

            .vebinars-description-img img {
                width: 100%;
                display: block;
            }

            .vebinars-right-description {
                width: 348px;

                .vebinars-date {
                    padding: 0px 0px 8px 0px;
                }

                .vebinars-descriptions-header {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding: 0px 0px 8px 0px;
                }

                .vebinars-description {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 0px 0px 16px 0px;
                }
            }
        }

    }
}

@media (max-width:744px) {
    .Vebinars-blog-main {

        .vebinars-capture {
            font-size: 30px;
            padding: 0px 0px 16px 0px;
        }

        .vebinars-description-wrapper {
            flex-direction: column-reverse;

            .vebinars-right-description {
                text-align: center;
                .vebinars-red-button {
                    width: 100%;

                    margin: 0px 0px 20px 0px;
                }
            }

            

        }

    }
}