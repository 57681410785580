.button-main{
    width: 100%;
    border: none;
    outline: none;
    background-color: rgba(28,28,28,0);
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}